import { Routes } from '@angular/router';

import { ConversorComponent } from './components';

export const ConversorRoutes: Routes = [
	{ 
		path: 'conversor-moedas', 
		component: ConversorComponent 
	}
];
