<nav class="navbar navbar-expand-lg navbar-dark bg-dark header">
  <a class="navbar-brand" [routerLink]="['/dashboard']">
    <img src="assets\imagens\AdvPushInicial.jpeg" class="img-responsive" alt="" title="" height="60" width="60" />
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleMenu()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [class.show]="mostrarMenuMobile" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" [routerLink]="['/dashboard']" (click)="toggleMenu()">
          PAINEL
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          PROCESSOS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/processos']" (click)="toggleMenu()">
            Lista de Processos
          </a>
          <a class="dropdown-item" [routerLink]="['/processos/processo-detalhado']" (click)="toggleMenu()">
            Processo Detalhado
          </a>
          <a class="dropdown-item" [routerLink]="['/processos/painel-prazos']" (click)="toggleMenu()">
            Painel de Prazos
          </a>
          <a class="dropdown-item" [routerLink]="['/processos/movimentacoes']" (click)="toggleMenu()">
            Movimentações
          </a>
          <a class="dropdown-item" [routerLink]="['/processos/dashboard']" (click)="toggleMenu()">
            Dashboard
          </a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          CLIENTES
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/clientes/pessoas-fisicas']" (click)="toggleMenu()">
            Pessoas Físicas
          </a>
          <a class="dropdown-item" [routerLink]="['/clientes/aniversarios']" (click)="toggleMenu()">
            Aniversários
          </a>
          <a class="dropdown-item" [routerLink]="['/clientes/pessoas-juridicas']" (click)="toggleMenu()">
            Pessoas Jurídicas
          </a>          
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          PERÍCIAS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/pericias']" (click)="toggleMenu()">
            Perícias
          </a>
          <a class="dropdown-item" [routerLink]="['/pericias/dashboard']" (click)="toggleMenu()">
            Dashboard
          </a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          AUDIÊNCIAS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/audiencias']" (click)="toggleMenu()">
            Audiências
          </a>
          <a class="dropdown-item" [routerLink]="['/audiencias/dashboard']" (click)="toggleMenu()">
            Dashboard
          </a>
        </div>
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          ATENDIMENTOS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/atendimentos']" (click)="toggleMenu()">
            Atendimentos
          </a>
          <a class="dropdown-item" [routerLink]="['/atendimentos/dashboard']" (click)="toggleMenu()">
            Dashboard
          </a>
        </div>
      </li> -->
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          FINANCEIRO
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/financeiro/recebimentos']" (click)="toggleMenu()">
            Recebimentos
          </a>
          <a class="dropdown-item" [routerLink]="['/financeiro/despesas']" (click)="toggleMenu()">
            Despesas
          </a>
          <a class="dropdown-item" [routerLink]="['/financeiro/resultado-geral']" (click)="toggleMenu()">
            Resultado Geral
          </a>
          <a class="dropdown-item" [routerLink]="['/financeiro/recebimentos-mes']" (click)="toggleMenu()">
            Recebimentos por Mês
          </a>
          <a class="dropdown-item" [routerLink]="['/financeiro/despesas-mes']" (click)="toggleMenu()">
            Despesas por Mês
          </a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          TAREFAS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/tarefas']" (click)="toggleMenu()">
            Lista de Tarefas
          </a>
          <a class="dropdown-item" [routerLink]="['/tarefas/dashboard']" (click)="toggleMenu()">
            Dashboard
          </a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          CONFIGURAÇÕES
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/configuracoes/geral']" (click)="toggleMenu()">
            Geral
          </a>
          <a class="dropdown-item" [routerLink]="['/configuracoes/advogados']" (click)="toggleMenu()">
            Advogados
          </a>
          <a class="dropdown-item" [routerLink]="['/configuracoes/processos']" (click)="toggleMenu()">
            Processos
          </a>
          <a class="dropdown-item" [routerLink]="['/configuracoes/clientes']" (click)="toggleMenu()">
            Clientes
          </a>
          <a class="dropdown-item" [routerLink]="['/configuracoes/financeiro']" (click)="toggleMenu()">
            Financeiro
          </a>
          <a class="dropdown-item" [routerLink]="['/configuracoes/tarefas']" (click)="toggleMenu()">
            Tarefas
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
