import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardRoutes } from './dashboard';

export const routes: Routes = [
	{
		path: '',
		redirectTo: '/dashboard',
		pathMatch: 'full'
	},
  {
    path: 'processos',
    loadChildren: () => import('./modules/processos/processos.module').then(m => m.ProcessosModule),
  },
  {
    path: 'clientes',
    loadChildren: () => import('./modules/clientes/clientes.module').then(m => m.ClientesModule),
  },
  {
    path: 'pericias',
    loadChildren: () => import('./modules/pericias/pericias.module').then(m => m.PericiasModule),
  },
  ...DashboardRoutes,
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
