<h1>Editar tarefa</h1>

<div class="well">
  <form #formTarefa="ngForm">
    <div class="form-group">
      <label for="nome">Tarefa:</label>
      <input 
        type="text"
        class="form-control"
        id="nome"
        name="nome"
        [(ngModel)]="tarefa.nome"
        #nome="ngModel"
        minlength="5" 
        required> 
      <div *ngIf="nome.errors && (nome.dirty || nome.touched)"
           class="alert alert-danger">
        <div [hidden]="!nome.errors.required">
          Digite a tarefa.
        </div>
        <div [hidden]="!nome.errors.minlength">
          A tarefa deve conter ao menos 5 caracteres.
        </div>
      </div>
    </div>
    <div class="form-group text-center">
      <input 
        type="submit"
        class="btn btn-success"
        (click)="atualizar()"
        value="Atualizar"
        [disabled]="!formTarefa.form.valid">
      <a [routerLink]="['/tarefas']"
        class="btn btn-default">
        <span class="glyphicon glyphicon-chevron-left" 
          aria-hidden="true"></span> Voltar
      </a>
    </div>
  </form>
</div>
