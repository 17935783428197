<div 
	class="modal fade" 
	[id]="id" 
	tabindex="-1" 
	role="dialog" 
	aria-labelledby="modalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button 
        	type="button" 
        	class="close" 
        	data-dismiss="modal" 
        	aria-label="Fechar">
        	<span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="modalLabel">
        	Cotação em {{ dataCotacao | dataBr }}
        </h4>
      </div>
      <div class="modal-body text-center">
        <div>
			<h2>
				{{ conversao.valor }} 
				{{ conversao.moedaDe }} 
				= 
				{{ valorConvertido }} 
				{{ conversao.moedaPara }}
			</h2>
			<p>
				1 {{ conversao.moedaPara }} = 
				{{ cotacaoPara }} {{ conversao.moedaDe }} 
				/
				1 {{ conversao.moedaDe }} = 
				{{ cotacaoDe }} {{ conversao.moedaPara }}
			</p>
		</div>
      </div>
      <div class="modal-footer">
        <button 
        	type="button" 
        	class="btn btn-success"
        	data-dismiss="modal"
        	(click)="novaConsulta()">
        	Nova consulta
        </button>
      </div>
    </div>
  </div>
</div>
