<nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <!-- suporte a mobile -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Visualizar itens</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <img src="assets\imagens\AdvPushInicial.jpeg" class="img-responsive" alt="" title="" height="60" width="60" />
    </div>
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav">
        <li>
          <a [routerLink]="['/dashboard']">
            PAINEL
          </a>
        </li>
        <li class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">PROCESSOS <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li>
              <a [routerLink]="['/dashboard']">
                PAINEL
              </a>
            </li>
            <li role="separator" class="divider"></li>
            <!-- <li>
              <a [routerLink]="['/calculadora']">
                Cliente
              </a>
            </li> -->
            <li>
              <!-- <a [routerLink]="['/conversor-moedas']"> -->
              <a [routerLink]="['/tarefas']">
                Processos
              </a>
            </li>
            <li>
              <a [routerLink]="['/tarefas']">
                Gerenciador de Processos
              </a>
            </li>
            <!-- <li>
              <a [routerLink]="['/jogo-da-velha']">
                Jogo da velha
              </a>
            </li> -->
          </ul>
        </li>
        <li>
          <a [routerLink]="['/dashboard']">
            CLIENTES
          </a>
        </li>
        <li>
          <a [routerLink]="['/dashboard']">
            PERICIAS
          </a>
        </li>
        <li>
          <a [routerLink]="['/dashboard']">
            AUDIÊNCIAS
          </a>
        </li>
        <li>
          <a [routerLink]="['/dashboard']">
            ATENDIMENTOS
          </a>
        </li>
        <li>
          <a [routerLink]="['/dashboard']">
            FINANCEIRO
          </a>
        </li>
        <li>
          <a [routerLink]="['/dashboard']">
            TAREFAS
          </a>
        </li>
        <li>
          <a [routerLink]="['/dashboard']">
            CONFIGURAÇÕES
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
