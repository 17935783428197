<h1>Cadastrar Processo</h1>

<div class="row">
  <div class="form-group col-md-4">
    <label for="nome">Numero Processo:</label>
    <input 
      type="text"
      class="form-control"
      id="nome"
      name="nome"
      [(ngModel)]="tarefa.nome"
      #nome="ngModel"
      minlength="5" 
      required> 
  </div>
  <div class="form-group col-md-4">
    <label for="nome">Tipo de ação:</label>  
    <input 
      type="text"
      class="form-control"
      id="nome"
      name="nome"
      [(ngModel)]="tarefa.nome"
      #nome="ngModel"
      minlength="5" 
      required>   
    <div *ngIf="nome.errors && (nome.dirty || nome.touched)"
         class="alert alert-danger">
      <div [hidden]="!nome.errors.required">
        Digite a tarefa.
      </div>
      <div [hidden]="!nome.errors.minlength">
        A tarefa deve conter ao menos 5 caracteres.
      </div>
    </div>
  </div>
</div>



<div class="well">
  <form #formTarefa="ngForm">    
    <div class="form-group text-center">
      <input 
        type="submit"
        class="btn btn-success"
        (click)="cadastrar()"
        value="Cadastrar"
        [disabled]="!formTarefa.form.valid">
      <a [routerLink]="['/tarefas']"
        class="btn btn-default">
        <span class="glyphicon glyphicon-chevron-left" 
          aria-hidden="true"></span> Voltar
      </a>
    </div>
  </form>
</div>
