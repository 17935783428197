import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { Tarefa } from './';
import { catchError, map } from 'rxjs/operators';
import { GenericService } from 'src/app/core/services/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class TarefaService extends GenericService {

  SERVER_URL = 'https://localhost:44335'

  constructor(protected http: HttpClient) { 
    super()
  }

  listarTodos(): Observable<Tarefa> {
  	const tarefas = localStorage['tarefas'];

  	return this.http.get(`${this.SERVER_URL}/Clientes`)
            .pipe(
                map(this.extractData), 
                catchError(this.serviceError));
  }

  cadastrar(tarefa: Tarefa): Observable<Tarefa> {
  	return this.http.get(`${this.SERVER_URL}/Clientes`)
            .pipe(
                map(this.extractData), 
                catchError(this.serviceError));  	
  }

  buscarPorId(id: number): Observable<Tarefa> {
    return this.http.get(`${this.SERVER_URL}/Clientes`)
            .pipe(
                map(this.extractData), 
                catchError(this.serviceError)); 
  }

  atualizar(tarefa: Tarefa): Observable<Tarefa> {
    return this.http.put(`${this.SERVER_URL}/Clientes`, tarefa)
            .pipe(
                map(this.extractData), 
                catchError(this.serviceError));
  }

  remover(id: number):  Observable<Tarefa> {
    return this.http.delete(`${this.SERVER_URL}/Clientes`)
            .pipe(
                map(this.extractData), 
                catchError(this.serviceError));;
  }

  alterarStatus(id: number): Observable<Tarefa> {
    return this.http.get(`${this.SERVER_URL}/Clientes`)
            .pipe(
                map(this.extractData), 
                catchError(this.serviceError)); 
  }  

}
