<h1>Processos</h1>

<table class="table table-striped table-bordered table-hover">
  <tbody>
    <tr>
    	<th>Numero Processo</th>
      <th>Concluída</th>
    	<th class="text-center">
        <a [routerLink]="['/tarefas/cadastrar']"
           class="btn btn-xs btn-success">
           <span class="glyphicon glyphicon-plus" 
             aria-hidden="true"></span> Novo
        </a>
    </th>
    </tr>
    <tr *ngFor="let tarefa of lista$ | async">
      <td 
        [tarefaConcluida]="tarefa.concluida"
        [class.success]="!tarefa.concluida">
        {{ tarefa.nome }}
      </td>
      <td style="width: 70px" class="text-center">
        <input 
          type="checkbox"
          (click)="alterarStatus(tarefa)"
          [value]="tarefa.id"
          [checked]="tarefa.concluida">
      </td>
      <td class="text-center" style="width: 200px">
        <a [routerLink]="['/tarefas/editar/'+tarefa.id]"
          title="Editar" alt="Editar"
          class="btn btn-xs btn-info">
          <span class="glyphicon glyphicon-pencil" 
            aria-hidden="true"></span> Editar
        </a>
        <a href="#" title="Remover" alt="Remover" 
          (click)="remover($event, tarefa)"
          class="btn btn-xs btn-danger">
          <span class="glyphicon glyphicon-remove" 
            aria-hidden="true"></span> Remover
        </a>
      </td>
    </tr>
  </tbody>
</table>

<!-- <p *ngIf="lista$.length==0">Nenhum Processo cadastrada.</p> -->