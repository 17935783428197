<!-- <div id="pie_chart"></div>
<div id="3d_pie_chart"></div>
<div id="donut_chart"></div>
<div id="bar_chart"></div>
<div id="line_chart"></div>
<div id="column_chart"></div> -->
<img
  src="assets\imagens\Inicial.png"
  class="img-responsive"
  alt=""
  title=""
  height="100%"
  width="100%"
/>


