import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import { throwError } from "rxjs";
import { LocalStorageUtils } from "../utils/localstorage";


@Injectable({
    providedIn: 'root'
})

export class GenericService {

    protected url!: string;
    public LocalStorage = new LocalStorageUtils();

    constructor() {
    }

    protected ObterHeaderJson() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    protected obterTokenOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.LocalStorage.obterTokenUsuario()}`
            })
        };
    }

    protected extractData(response: any) {
        return response.data == undefined ? {} : response.data;
    }

    protected serviceError(response: Response | any) {
        let customError: string[] = [];
        let errors: string[] = [];
        let customResponse = { error: { errors } };

        if (response instanceof HttpErrorResponse) {

            if (response.statusText === "Unknown Error") {
                customError.push("Ocorreu um erro desconhecido");
                response.error.errors = customError;
            }
        }
        if (response.status === 500) {
            customError.push("Ocorreu um erro no processamento, tente novamente mais tarde ou contate o nosso suporte.");

            // Erros do tipo 500 não possuem uma lista de erros
            // A lista de erros do HttpErrorResponse é readonly
            customResponse.error.errors = customError;
            return throwError(customResponse);
        }

        console.error(response);
        return throwError(response);
    }

    obterQuery(pageEvent: any): string {
        var query = pageEvent.page != 0 ? `page=${pageEvent.page}` : "";
        if (pageEvent.pageSize != 0) {
            if (query != "") query += "&";
            query += `pageSize=${pageEvent.pageSize}`;
        }
        if (pageEvent.searchTerm != "") {
            if (query != "") query += "&";
            query += `searchTerm=${encodeURIComponent(pageEvent.searchTerm)}`;
        }
        if (pageEvent.sortColumn != "") {
            if (query != "") query += "&";
            query += `sortColumn=${pageEvent.sortColumn}`;
        }
        if (pageEvent.sortDirection != "") {
            if (query != "") query += "&";
            query += `sortDirection=${pageEvent.sortDirection}`;
        }
        return query;
    }
}
