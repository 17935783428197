import { Routes } from '@angular/router';

import { JogoDaVelhaComponent } from './jogo-da-velha.component';

export const JogoDaVelhaRoutes: Routes = [
	{ 
		path: 'jogo-da-velha', 
		component: JogoDaVelhaComponent 
	}
];
