import { Routes } from '@angular/router';

import { CalculadoraComponent } from './components';

export const CalculadoraRoutes: Routes = [
	{ 
		path: 'calculadora', 
		component: CalculadoraComponent 
	}
];
