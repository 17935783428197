export class LocalStorageUtils {
    
    public obterUsuario() {
        return JSON.parse(localStorage.getItem('usuarioId') ?? '');
    }

    public salvarDadosLocaisUsuario(response: any) {
        this.salvarTokenUsuario(response.accessToken);
        this.salvarUsuario(response.usuarioToken);
    }

    public limparDadosLocaisUsuario() {
        localStorage.removeItem('tokenId');
        localStorage.removeItem('usuarioId');
        localStorage.removeItem('grupoEmpresaId');
        localStorage.removeItem('empresaId');
    }

    public obterTokenUsuario(): string {
        return localStorage.getItem('tokenId') ?? '';
    }

    public salvarTokenUsuario(token: string) {
        localStorage.setItem('tokenId', token);
    }

    public salvarUsuario(user: string) {
        localStorage.setItem('usuarioId', JSON.stringify(user));
    }

    public obterGrupoEmpresa() : string {
        return localStorage.getItem('grupoEmpresaId') ?? '';
    }
    public salvarGrupoEmpresa(grupoEmpresa: string) {
        localStorage.setItem('grupoEmpresaId', grupoEmpresa);
    }
    public obterEmpresa(): string {
        return localStorage.getItem('empresaId') ?? '';
    }    
    public salvarEmpresa(empresa: string) {
        localStorage.setItem('empresaId', empresa);
    }
}